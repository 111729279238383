h1 {
  font-size: 30px;
  font-weight: 200;
  padding: 0.3em; /*文字周りの余白*/
  /*線の種類（実線） 太さ 色*/
  border-bottom: solid 4px  #5FCEB3;
}
h2 {
  padding: 0.5em; /*文字周りの余白*/
  color: #010101; /*文字色*/
  background: #EFF8F2; /*背景色*/
  border-left: solid 4px #5FCEB3; /*左線*/
}
h3 {
  font-size: 32px;
  position: relative;
  overflow: hidden;
  padding: 0.4rem 2rem 0rem 100px;
  word-break: break-all;
  border-top: 3px solid #697774;
  border-radius: 12px 0 0 0;
  display: inline-block;
}

h3 span {
  font-size: 30px;
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  padding: 3px 20px;
  color: #fff;
  border-radius: 10px 0 20px 10px;
  background: #697774;
}
li{
  color: #5FCEB3;
}
li span {
  font-size: 20px;
  color: #010101;
}